define("@nsf/ember-properties-loader/components/property-loader-values/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="table properties-loader-values">
      <thead>
          <tr>
              <th width="1%"></th>
              <th width="1%">Type</th>
              <th width="1%">Source</th>
              <th>Value</th>
          </tr>
      </thead>
      <tbody>
          {{#each this.propertyDetails as |prop|}}
              <tr class="prop-type-{{prop.type}}">
                  <th class="prop-chain">{{prop.chain}}</th>
                  <td class="prop-type">{{prop.type}}</td>
                  <td class="prop-sources">
                      <ul>
                          {{#each prop.sources as |source|}}
                              <li>{{source}}</li>
                          {{/each}}
                      </ul>
                  </td>
                  <td class="prop-value">{{prop.value}}</td>
              </tr>
          {{/each}}
      </tbody>
  </table>
  */
  {
    "id": "/1vfNDWJ",
    "block": "[[[10,\"table\"],[14,0,\"table properties-loader-values\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[14,\"width\",\"1%\"],[12],[13],[1,\"\\n            \"],[10,\"th\"],[14,\"width\",\"1%\"],[12],[1,\"Type\"],[13],[1,\"\\n            \"],[10,\"th\"],[14,\"width\",\"1%\"],[12],[1,\"Source\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Value\"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"propertyDetails\"]]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[15,0,[29,[\"prop-type-\",[30,1,[\"type\"]]]]],[12],[1,\"\\n                \"],[10,\"th\"],[14,0,\"prop-chain\"],[12],[1,[30,1,[\"chain\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[14,0,\"prop-type\"],[12],[1,[30,1,[\"type\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[14,0,\"prop-sources\"],[12],[1,\"\\n                    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,1,[\"sources\"]]],null]],null],null,[[[1,\"                            \"],[10,\"li\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"],[10,\"td\"],[14,0,\"prop-value\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"prop\",\"source\"],false,[\"table\",\"thead\",\"tr\",\"th\",\"tbody\",\"each\",\"-track-array\",\"td\",\"ul\",\"li\"]]",
    "moduleName": "@nsf/ember-properties-loader/components/property-loader-values/template.hbs",
    "isStrictMode": false
  });
});